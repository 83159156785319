<script setup lang="ts">
import { cloneDeep } from "lodash"
import { pushBannerClickCtaEvent } from "~/utils/analytics/clickCta"
import { PromoProps } from "./Promo.props"

const props = defineProps<PromoProps>()

const emit = defineEmits<{ (e: "onButtonClick"): void }>()

const handleGtm = (label: string) => {
  pushBannerClickCtaEvent(label)
}

const handleCta = (label: string) => {
  emit("onButtonClick")
  handleGtm(label)
}

const _backgroundImage = computed(() => {
  let newBackgroundImage = props.backgroundImage
    ? cloneDeep(props.backgroundImage)
    : undefined
  if (newBackgroundImage) {
    newBackgroundImage = setAdditionalImageProps(newBackgroundImage, {
      deleteWidthFullFromStyle: true
    })

    newBackgroundImage.imgAttrs = setAdditionalImageAttrs(
      newBackgroundImage.imgAttrs,
      {
        class: "md:w-full"
      }
    )
  }

  return newBackgroundImage
})
</script>

<template>
  <section
    class="
      grid-standard
      bg-gradient-to-r
      from-green-main
      to-black-main
      py-6
      md:py-16
    "
  >
    <div
      v-if="_backgroundImage"
      class="
        col-span-full
        mb-10
        flex
        items-center
        justify-center
        md:order-1
        md:col-span-6
        md:mb-0
      "
    >
      <UtilsWrapperImage v-bind="_backgroundImage" />
    </div>
    <div class="col-span-full md:col-span-6">
      <p
        class="
          elephant-bold
          md:whale-bold
          lg:dyno-bold
          mb-2
          uppercase
          text-white
        "
      >
        {{ title }}
      </p>
      <UtilsMarkdown
        class="beaver md:pig-medium markdown__strong-positive-100 text-white"
        :content="description"
      />
      <UtilsButton
        v-if="cta?.text"
        v-bind="cta"
        class="
          beaver-medium
          mt-6
          inline-block
          text-center
          md:mt-7
          md:w-64
          lg:mt-4
          [&>*]:whitespace-normal
        "
        theme="contained-yellow"
        @click="() => handleCta((cta!.text as string))"
        fluid
      />
      <div v-if="extraText" class="mt-6 text-white md:mt-7 lg:mt-7">
        <UtilsMarkdown
          v-if="extraText"
          class="
            markdown__strong-yellow-main
            mb-2
            text-sm
            font-bold
            uppercase
            lg:text-base
          "
          :content="extraText"
        />
        <UtilsMarkdown
          v-if="disclaimer"
          class="snail md:mouse"
          :content="disclaimer"
        />
      </div>
    </div>
  </section>
</template>
